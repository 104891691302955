




















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class WarehouseItemDashboard extends Vue {

  tabs = [
    { name: "warehouse_items_list", label: "Articoli" },
    { name: "warehouse_items_diff", label: "Movimenti" },
  ];
}
